var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{ref:"container",staticClass:"py-0 px-0",attrs:{"fluid":""}},[_c('v-tabs',{ref:"tabs",attrs:{"active-class":"white","background-color":"grey lighten-3","hide-slider":""}},[_c('v-tab',{attrs:{"to":{
                name: 'wo-ready-for-packing',
            }}},[_vm._v(" READY FOR PACKING ")]),_c('v-tab',{attrs:{"to":{
                name: 'main-packing',
            }}},[_vm._v(" PACKING ")]),_c('v-tab',{attrs:{"to":{
                name: 'pending-packing',
            }}},[_vm._v(" PENDING ")]),_c('v-tab',{attrs:{"to":{
                name: 'scheduled-packing',
            }}},[_vm._v(" SCHEDUlED ")]),_c('v-tab',{attrs:{"to":{
                name: 'referred-packing',
            }}},[_vm._v(" REFERRED ")]),_c('v-tab',{attrs:{"to":{
                name: 'shipped-packing',
            }}},[_vm._v(" SHIPPED ")]),_c('v-tab',{attrs:{"to":{
                name: 'completed-packing',
            }}},[_vm._v(" COMPLETED ")])],1),_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"overflow-y-auto px-4 pt-4",style:(`height: ${_vm.height}px`)},[_c('router-view')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }